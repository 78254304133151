import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectList from '../views/ProjectList.vue'
import ProjectView from '../views/ProjectView.vue'
import CompanyView from '../views/CompanyView.vue'
import AuthenticateView from '../views/AuthenticateView.vue'
import ServiceReqList from '../views/ServiceReqList.vue'
import PackLists from '../views/PackLists.vue'
import ServiceReqView from '../views/ServiceReqView.vue'
import AISecretary from '../views/AISecretary.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ProjectList',
    component: ProjectList
  },
  {
    path: '/packlists',
    name: 'PackLists',
    component: PackLists
  },
  {
    path: '/service/requests',
    name: 'ServiceReqList',
    component: ServiceReqList
  },
  {
    path: '/service/view/:id(.*)',
    name: 'ServiceReqView',
    component: ServiceReqView
  },
  {
    path: '/project/:id(.*)',
    name: 'ProjectView',
    component: ProjectView
  },
  {
    path: '/company/:id(.*)',
    name: 'CompanyView',
    component: CompanyView
  },
  {
    path: '/authenticate',
    name: 'AuthenticateView',
    component: AuthenticateView
  },
  {
    path: '/ai/secretary',
    name: 'AISecretary',
    component: AISecretary
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
