<template>
  <v-container>
    <v-app-bar elevate-on-scroll app color="primary" dark>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title
        ><b>{{ formData.Office_Name || "New company" }}</b></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon @click="save" v-show="dataChanged" :loading="loading">
        <v-icon>mdi-floppy</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card filled>
      <form>
        <v-container style="padding-bottom: 0px">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Customer No."
                messages="Changing the Customer Number creates a new customer."
                type="number"
                filled
                v-model="formData.CustNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="VAT"
                filled
                v-model="formData.VAT"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <b>Office</b> <br /><br />
              <v-text-field
                label="Company Name"
                filled
                v-model="formData.Office_Name"
              ></v-text-field>
              <v-text-field
                label="Street 1"
                filled
                v-model="formData.Office_Street1"
              ></v-text-field>
              <v-text-field
                label="Street 2"
                filled
                v-model="formData.Office_Street2"
              ></v-text-field>
              <v-text-field
                label="City"
                filled
                v-model="formData.Office_City"
              ></v-text-field>
              <v-text-field
                label="ZIP"
                filled
                v-model="formData.Office_ZIP"
              ></v-text-field>
              <v-text-field
                label="Country"
                filled
                v-model="formData.Office_Country"
              ></v-text-field>
              <v-text-field
                label="Contact Name"
                filled
                v-model="formData.Office_Contact_Name"
              ></v-text-field>
              <v-text-field
                label="Contact Phone Number"
                filled
                v-model="formData.Office_Contact_PhoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <b>Bill-To</b> <br /><br />
              <v-text-field
                label="Company Name"
                filled
                v-model="formData.BillTo_Name"
              ></v-text-field>
              <v-text-field
                label="Street 1"
                filled
                v-model="formData.BillTo_Street1"
              ></v-text-field>
              <v-text-field
                label="Street 2"
                filled
                v-model="formData.BillTo_Street2"
              ></v-text-field>
              <v-text-field
                label="City"
                filled
                v-model="formData.BillTo_City"
              ></v-text-field>
              <v-text-field
                label="ZIP"
                filled
                v-model="formData.BillTo_ZIP"
              ></v-text-field>
              <v-text-field
                label="Country"
                filled
                v-model="formData.BillTo_Country"
              ></v-text-field>
              <v-text-field
                label="Contact Name"
                filled
                v-model="formData.BillTo_Contact_Name"
              ></v-text-field>
              <v-text-field
                label="Contact Phone Number"
                filled
                v-model="formData.BillTo_Contact_PhoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <b>Delivery-To</b> <br /><br />
              <v-text-field
                label="Company Name"
                filled
                v-model="formData.Delivery_Name"
              ></v-text-field>
              <v-text-field
                label="Street 1"
                filled
                v-model="formData.Delivery_Street1"
              ></v-text-field>
              <v-text-field
                label="Street 2"
                filled
                v-model="formData.Delivery_Street2"
              ></v-text-field>
              <v-text-field
                label="City"
                filled
                v-model="formData.Delivery_City"
              ></v-text-field>
              <v-text-field
                label="ZIP"
                filled
                v-model="formData.Delivery_ZIP"
              ></v-text-field>
              <v-text-field
                label="Country"
                filled
                v-model="formData.Delivery_Country"
              ></v-text-field>
              <v-text-field
                label="Contact Name"
                filled
                v-model="formData.Delivery_Contact_Name"
              ></v-text-field>
              <v-text-field
                label="Contact Phone Number"
                filled
                v-model="formData.Delivery_Contact_PhoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-card>
    <br />
    <br />
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Got it
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "ProjectView",
  data() {
    return {
      dataChanged: false,

      snackbar: {
        show: false,
        text: "",
      },

      handler: null,

      loading: false,

      formData: {
        CustNumber: "",
        VAT: "",

        Office_Name: "",
        Office_Street1: "",
        Office_Street2: "",
        Office_City: "",
        Office_ZIP: "",
        Office_Country: "",
        Office_Contact_Name: "",
        Office_Contact_PhoneNumber: "",

        BillTo_Name: "",
        BillTo_Street1: "",
        BillTo_Street2: "",
        BillTo_City: "",
        BillTo_ZIP: "",
        BillTo_Country: "",
        BillTo_Contact_Name: "",
        BillTo_Contact_PhoneNumber: "",

        Delivery_Name: "",
        Delivery_Street1: "",
        Delivery_Street2: "",
        Delivery_City: "",
        Delivery_ZIP: "",
        Delivery_Country: "",
        Delivery_Contact_Name: "",
        Delivery_Contact_PhoneNumber: "",
      },
    };
  },

  methods: {
    save() {
      var self = this;
      if (!this.formData.CustNumber) {
        self.snackbar.show = true;
        self.snackbar.text = "No Customer Number assigned.";
        return;
      }

      this.loading = true;

      window.removeEventListener("beforeunload", this.handler);
      
      var data = JSON.stringify({
        token: localStorage.getItem("token"),
        table: "Companies",
        payload: this.formData,
      });

      var config = {
        method: "post",
        url: "https://aceapi.diconde.biz/database/update",
        headers: {
          Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          self.loading = false;
          self.dataChanged = false;

          self.snackbar.show = true;
          self.snackbar.text = response.data.reason;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  mounted() {
    if (this.$route.params && this.$route.params.id == "new") return;

    var self = this;
    var data = JSON.stringify({
      token: localStorage.getItem("token"),
      table: "Companies",
      search: this.$route.params.id,
    });

    var config = {
      method: "post",
      url: "https://aceapi.diconde.biz/database/query",
      headers: {
        Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("JSON: " + JSON.stringify(response.data));
        if (response.data.length == 0 || response.data == null) {
          self.$router.push("/");
          return;
        }

        self.formData = response.data[0];

        setTimeout(function () {
          self.dataChanged = false;
        }, 10);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  watch: {
    "formData.CustNumber"(val) {
      console.log(val);
      // Silently change URL parameter to val
      this.$router.replace({
        name: "CompanyView",
        params: {
          id: val,
        },
      });
    },
    formData: {
      handler() {
        if (Number(this.formData.CustNumber)) {
          this.dataChanged = true;

          this.handler = function (e) {
            // Cancel the event
            e.preventDefault();
            // Chrome requires returnValue to be set
            e.returnValue = "";
          };

          window.addEventListener("beforeunload", this.handler);
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      const answer = window.confirm(
        "You have made changes to the project. Are you sure you want to leave?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
