<template>
  <v-container>
    <Header name="Projects" :buttons="buttons" />

    <v-subheader>Ongoing projects</v-subheader>
    <div v-for="(project, index) in ongoingProjects" :key="project.PONumber">
      <v-card
        color="orange lighten-4"
        @click="$router.push('/project/' + project.PONumber)"
      >
        <v-card-title
          ><v-menu
            v-model="menu[index]"
            bottom
            right
            transition="scale-transition"
            origin="top left"
          >
            <template v-slot:activator="{ on }">
              <v-chip pill v-on="on">
                {{ getCompanyInfo(project.CustNumber).Office_Name }}
              </v-chip>
            </template>
            <v-card width="300">
              <v-list dark>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      getCompanyInfo(project.CustNumber)
                        ? getCompanyInfo(project.CustNumber).Office_Name
                        : "Unnamed company"
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >#{{ getCompanyInfo(project.CustNumber).CustNumber }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="menu[index] = false">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-action>
                  <v-list-item-subtitle>{{
                    getCompanyInfo(project.CustNumber).Office_Contact_Name
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-action>
                  <v-list-item-subtitle>{{
                    getCompanyInfo(project.CustNumber)
                      .Office_Contact_PhoneNumber
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    @click="
                      $router.push(
                        '/company/' +
                          getCompanyInfo(project.CustNumber).CustNumber
                      )
                    "
                    block
                    text
                    >Edit</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu></v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              {{ project.PONumber }}
              {{
                project.ReqDelDate
                  ? "- Req. Delivery Date: " + project.ReqDelDate
                  : ""
              }}
            </v-col>
            <v-col cols="12" sm="6">
              <span style="white-space: pre-wrap">
                {{ project.Remarks }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </div>
    <v-divider></v-divider>
    <v-subheader>Finished projects</v-subheader>
    <div v-for="(project, index) in finishedProjects" :key="project.PONumber">
      <v-card
        color="green lighten-4"
        @click="$router.push('/project/' + project.PONumber)"
      >
        <v-card-title
          ><v-menu
            v-model="menu[100 + index]"
            bottom
            right
            transition="scale-transition"
            origin="top left"
          >
            <template v-slot:activator="{ on }">
              <v-chip pill v-on="on">
                {{ getCompanyInfo(project.CustNumber).Office_Name }}
              </v-chip>
            </template>
            <v-card width="300">
              <v-list dark>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      getCompanyInfo(project.CustNumber)
                        ? getCompanyInfo(project.CustNumber).Office_Name
                        : "Unnamed company"
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >#{{ getCompanyInfo(project.CustNumber).CustNumber }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="menu[100 + 100 + index] = false">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-action>
                  <v-list-item-subtitle>{{
                    getCompanyInfo(project.CustNumber).Office_Contact_Name
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-action>
                  <v-list-item-subtitle>{{
                    getCompanyInfo(project.CustNumber)
                      .Office_Contact_PhoneNumber
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    @click="
                      $router.push(
                        '/company/' +
                          getCompanyInfo(project.CustNumber).CustNumber
                      )
                    "
                    block
                    text
                    >Edit</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu></v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              {{ project.PONumber }}
              {{
                project.ReqDelDate
                  ? "- Req. Delivery Date: " + project.ReqDelDate
                  : ""
              }}
            </v-col>
            <v-col cols="12" sm="6">
              <span style="white-space: pre-wrap">
                {{ project.Remarks }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </div>
    <br />
    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
var axios = require("axios").default;
var Header =
  require("@/components/HeaderComp.vue").default;

export default {
  name: "ProjectList",
  components: {
    Header,
  },
  data: () => ({
    buttons: [
    {
        text: "Packlists",
        icon: "mdi-table-arrow-right",
        to: "/packlists",
      },
      {
        text: "Add project",
        icon: "mdi-plus",
        to: "/project/new",
      },

    ],
    
    group: 0,

    snackbar: false,
    text: "",

    companies: [],
    menu: {},
    allProjects: [],
    ongoingProjects: [],
    finishedProjects: [],
  }),
  methods: {
    // Get company info by CustNumber from this.companies
    getCompanyInfo: function (CustNumber) {
      for (var i = 0; i < this.companies.length; i++) {
        if (this.companies[i].CustNumber == CustNumber) {
          return this.companies[i];
        }
      }
    },
  },
  mounted() {
    var self = this;

    // Companies
    var data = JSON.stringify({
      token: localStorage.getItem("token"),
      table: "Companies",
    });

    var config = {
      method: "post",
      url: "https://aceapi.diconde.biz/database/query",
      headers: {
        Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        self.companies = response.data;

        data = JSON.stringify({
          token: localStorage.getItem("token"),
          table: "Projects",
        });

        config = {
          method: "post",
          url: "https://aceapi.diconde.biz/database/query",
          headers: {
            Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            self.allProjects = response.data;
            self.ongoingProjects = self.allProjects.filter(
              (project) => project.ProjectCompleted == "0"
            );
            self.finishedProjects = self.allProjects.filter(
              (project) => project.ProjectCompleted == "1"
            );

            self.text =
              self.allProjects.length + " projects loaded successfully.";
            self.snackbar = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>
