<template>
  <v-container>
    <Header name="AI Secretary" />

    <v-card>
      <v-card-title>Email composing</v-card-title>
      <v-card-subtitle
        >Generate an email based on template sentences.
      </v-card-subtitle>

      <v-card-text>
        <v-text-field
          v-model="action"
          label="Instructions"
          dense
          outlined
        ></v-text-field>
        <v-textarea
          v-model="text"
          label="Input"
          outlined
          hide-details
          :rows="generated ? 6 : 12"
        ></v-textarea>
        <br />
        <v-btn
          block
          color="primary"
          :disabled="loading"
          :loading="loading"
          @click="generateEmail"
          >Generate</v-btn
        >
        <br />
        <v-divider></v-divider>
        <br />
        <v-textarea
          v-model="output"
          label="Output"
          outlined
          :rows="generated ? 12 : 6"
          :disabled="!generated || loading"
          :loading="loading"
        ></v-textarea>
        <v-btn
          block
          :color="copied ? 'success' : 'primary'"
          @click="copyEmail"
          outlined
          >{{ copied ? "Copied" : "Copy output" }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
//var axios = require("axios").default;
var Header = require("@/components/HeaderComp.vue").default;

export default {
  name: "ProjectList",
  components: {
    Header,
  },
  data: () => ({
    action: "Write a formal email based on the notes given",
    text: "Dear Mr./Mrs. [RECIPIENT],\n\n[TEXT]\n\nKind regards,\nArray Corporation Europe",
    output: "",

    copied: false,
    loading: false,
    generated: false,
  }),
  methods: {
    copyEmail() {
      if (this.copied) return;

      navigator.clipboard.writeText(this.output);

      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    generateEmail() {
      if (this.loading) return;

      this.loading = true;

      var self = this;
      axios
        .post("https://aceapi.diconde.biz/ai/compose", {
          action: this.action,
          text: this.text,
          token: localStorage.getItem("token"),
        })
        .then((response) => {
          self.output = response.data.output;
          self.output = self.output.trim();
          self.generated = true;
          self.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
