<template>
  <div>
    <v-app-bar elevate-on-scroll app color="primary" dark>
      <v-btn icon disabled> </v-btn>
      <v-toolbar-title><b>Authentication</b></v-toolbar-title>
    </v-app-bar>
    <div class="center-screen">
      <v-col cols="12" sm="4">
        <v-card :disabled="loading" :loading="loading">
          <v-card-title>Authentication required</v-card-title>
          <v-card-subtitle
            >You are logging in on a new device or a new network.<br />
            Enter your 2FA code below to access the requested
            resources.</v-card-subtitle
          >
          <br />
          <v-col>
            <v-otp-input
              v-model="code"
              @finish="authenticate"
              length="6"
              type="number"
            ></v-otp-input>
          </v-col>
        </v-card>
      </v-col>
    </div>
    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Got it </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;

export default {
  name: "ProjectList",
  data: () => ({
    code: "",
    loading: false,
    snackbar: false,
    text: `Hello, I'm a snackbar`,
  }),
  methods: {
    authenticate() {
      var self = this;
      this.loading = true;

      var config = {
        method: "get",
        url: "https://aceapi.diconde.biz/authenticate/" + this.code,
        headers: {
          Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
        },
        credentials: "include",
      };

      axios(config)
        .then(function (response) {
          self.loading = false;
          if (response.data.success) {
            console.log(response.data.token);
            localStorage.setItem("token", response.data.token);
            self.$router.push("/");
          } else {
            console.log(response.data.reason);
            self.code = "";
            self.text = response.data.reason;
            self.snackbar = true;
          }
        })
        .catch(function (error) {
          self.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
</style>
