<template>
  <div>
    <v-container>
      <v-app-bar
        elevate-on-scroll
        app
        :color="formData.ProjectCompleted ? 'green' : 'orange'"
        dark
      >
        <v-btn icon @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title
          ><b>{{ formData.PONumber || "New project" }}</b></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="sheet = true" :loading="loading">
          <v-icon>mdi-import</v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="!select"
          @click="save"
          v-show="dataChanged"
          :loading="loading"
        >
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card>
        <v-card-subtitle><b> Customer Details </b></v-card-subtitle>
        <form>
          <v-container style="padding-bottom: 0px">
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  label="Customer Name"
                  filled
                  :items="items"
                  v-model="select"
                  item-text="Office_Name"
                  item-value="CustNumber"
                  return-object
                  auto-select-first
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.Office_Name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.CustNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Customer No."
                  disabled
                  filled
                  type="number"
                  :value="select ? select.CustNumber : ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </form>
        <v-card-actions>
          <v-btn
            v-if="!select"
            color="primary"
            text
            block
            @click="$router.push('/company/new')"
          >
            ADD NEW COMPANY
          </v-btn>
          <v-btn
            v-if="select"
            color="primary"
            text
            block
            @click="
              if (select) {
                $router.push('/company/' + select.CustNumber);
              }
            "
          >
            EDIT
          </v-btn>
        </v-card-actions>
      </v-card>
      <br />
      <v-card filled>
        <v-card-subtitle><b> Project Details </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="2">
                <v-checkbox
                  hide-details
                  v-model="formData.ProjectCompleted"
                  :label="`Completed`"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  persistent-hint
                  messages="Changing the Project Number creates a new project."
                  label="Project No."
                  v-model="formData.PONumber"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs1"
                      label="Order Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.OrderDate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea v-model="formData.Remarks" label="Remarks" filled>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card filled>
        <v-card-subtitle><b> Quotation </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  label="Quotation No."
                  v-model="formData.QuotNumber"
                  filled
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs2"
                      label="Quotation Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.QuotDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="114px">Article No.</th>
                        <th class="text-left" width="368px">Description</th>
                        <th class="text-left" width="114px">S/N</th>
                        <th class="text-left" width="114px">Qty #</th>
                        <th class="text-left" width="114px">Price</th>
                        <th class="text-left" width="114px">Tax</th>
                        <th class="text-right" width="114px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in formData.QuotRows"
                        :key="item.id"
                      >
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            dense
                            outlined
                            v-model="item.ArtNumber"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Description"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.SerialNumber"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            type="number"
                            dense
                            outlined
                            v-model="item.Quantity"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            type="number"
                            outlined
                            prefix="€"
                            v-model="item.Price"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            type="number"
                            suffix="%"
                            v-model="item.Tax"
                          ></v-text-field>
                        </td>
                        <td class="text-right">
                          <v-btn
                            @click="removeRow(formData.QuotRows, index)"
                            icon
                            tabindex="-1"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br />
                <v-row>
                  <v-col cols="0" sm="9"></v-col>
                  <v-col cols="12" sm="3">
                    <v-btn outlined block @click="addRow(formData.QuotRows)"
                      >+ Add line</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card>
        <v-card-subtitle><b> Order </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs3"
                      label="Order Acceptance Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.OrdrAccDate"
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="114px">Article No.</th>
                        <th class="text-left" width="368px">Description</th>
                        <th class="text-left" width="114px">S/N</th>
                        <th class="text-left" width="114px">Qty #</th>
                        <th class="text-left" width="114px">Price</th>
                        <th class="text-left" width="114px">Tax</th>
                        <th class="text-right" width="114px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in formData.OrdrRows"
                        :key="item.id"
                      >
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.ArtNumber"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Description"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.SerialNumber"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Quantity"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            prefix="€"
                            v-model="item.Price"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="%"
                            v-model="item.Tax"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            tabindex="-1"
                            @click="removeRow(formData.OrdrRows, index)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br />
                <v-row>
                  <v-col cols="12" sm="9">
                    <v-btn @click="cloneRows('QuotRows', 'OrdrRows')" text block
                      >Copy from quotation</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-btn @click="addRow(formData.OrdrRows)" outlined block
                      >+ Add line</v-btn
                    >
                  </v-col>
                </v-row>
                <br />
                <v-divider></v-divider>
                <br />
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      hide-details
                      v-model="formData.ShipmentCondix"
                      label="Shipment Condix"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      hide-details
                      v-model="formData.PaymentCondix"
                      label="Payment Condix"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card>
        <v-card-subtitle><b> Dates </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs4"
                      clearable
                      label="Requested Delivery Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.ReqDelDate"
                    @input="menu4 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu5"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs5"
                      clearable
                      label="Estimated Delivery Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.EstDelDate"
                    @input="menu5 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card>
        <v-card-subtitle><b> CvO </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  label="CvO Invoice No."
                  filled
                  v-model="formData.CvOInvNr"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu6"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs6"
                      clearable
                      label="CvO Invoice Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.CvOInvDate"
                    @input="menu6 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="114px">Article No.</th>
                        <th class="text-left" width="368px">Description</th>
                        <th class="text-left" width="114px">S/N</th>
                        <th class="text-left" width="114px">Qty #</th>
                        <th class="text-left" width="114px">Price</th>
                        <th class="text-left" width="114px">Tax</th>
                        <th class="text-right" width="114px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in formData.CvORows"
                        :key="item.id"
                      >
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.ArtNumber"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Description"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.SerialNumber"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Quantity"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            prefix="€"
                            v-model="item.Price"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="%"
                            v-model="item.Tax"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            @click="removeRow(formData.CvORows, index)"
                            tabindex="-1"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br />
                <v-row>
                  <v-col cols="12" sm="9">
                    <v-btn text block @click="cloneRows('OrdrRows', 'CvORows')"
                      >Copy from order</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-btn outlined block @click="addRow(formData.CvORows)"
                      >+ Add line</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card>
        <v-card-subtitle><b> Prepayment </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  label="Prepay Invoice No."
                  filled
                  type="number"
                  v-model="formData.PrepayInvNr"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu13"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs13"
                      clearable
                      label="Prepay Date"
                      readonly
                      filled
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.PrepayInvDate"
                    @input="menu13 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card>
        <v-card-subtitle><b> Delivery </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="114px">Article No.</th>
                        <th class="text-left" width="368px">Description</th>
                        <th class="text-left" width="114px">S/N</th>
                        <th class="text-left" width="114px">Qty #</th>
                        <th class="text-left" width="114px">Price</th>
                        <th class="text-left" width="114px">Tax</th>
                        <th class="text-right" width="114px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in formData.DelRows"
                        :key="item.id"
                      >
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.ArtNumber"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Description"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.SerialNumber"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            v-model="item.Quantity"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            prefix="€"
                            v-model="item.Price"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="%"
                            v-model="item.Tax"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            @click="removeRow(formData.DelRows, index)"
                            tabindex="-1"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br />
                <v-row>
                  <v-col cols="12" sm="9">
                    <v-btn text block @click="cloneRows('OrdrRows', 'DelRows')"
                      >Copy from order</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-btn outlined block @click="addRow(formData.DelRows)"
                      >+ Add line</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />

      <v-card>
        <v-card-subtitle><b> Shipping </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="215px">Length</th>
                        <th class="text-left" width="215px">Width</th>
                        <th class="text-left" width="215px">Height</th>
                        <th class="text-left" width="215px">N/W</th>
                        <th class="text-left" width="215px">G/W</th>
                        <th class="text-right" width="215px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in formData.ShipRows"
                        :key="item.id"
                      >
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="m"
                            v-model="item.Length"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="m"
                            v-model="item.Width"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="m"
                            v-model="item.Height"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="kg"
                            v-model="item['N/W']"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            dense
                            outlined
                            suffix="kg"
                            v-model="item['G/W']"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td class="text-right">
                          <v-btn
                            icon
                            @click="removeRow(formData.ShipRows, index)"
                            tabindex="-1"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br />
                <v-row>
                  <v-col cols="12" sm="9"> </v-col>
                  <v-col cols="12" sm="3">
                    <v-btn outlined block @click="addShipRow(formData.ShipRows)"
                      >+ Add line</v-btn
                    >
                  </v-col>
                </v-row>
                <br />
                <v-divider></v-divider>
                <br />
                <v-row>
                  <v-col cols="12" sm="4" class="text-center">
                    <b>Total Volume:</b>
                    {{ Number(totalVolume).toFixed(3) }} m<sup>3</sup>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    <b>Total N/W:</b>
                    {{ totalNetWeight }} kg
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    <b>Total G/W:</b>
                    {{ totalGrossWeight }} kg
                  </v-col>
                </v-row>
                <br />
                <v-divider></v-divider>
                <br />
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      label="# of boxes"
                      hide-details
                      filled
                      type="number"
                      v-model="formData.TotNrOfBoxes"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Shipping Invoice No."
                      type="number"
                      hide-details
                      filled
                      v-model="formData.ShipInvNr"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="menu7"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormattedMomentjs7"
                          clearable
                          label="Shipping Invoice Date"
                          readonly
                          filled
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.ShipInvDate"
                        @input="menu7 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Package List No."
                      type="number"
                      hide-details
                      v-model="formData.PackLstNr"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="menu8"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormattedMomentjs8"
                          clearable
                          label="Package List Date"
                          readonly
                          filled
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.PackLstDate"
                        @input="menu8 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="AWB"
                      v-model="formData.CarrierAWB"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Carrier Customer Ref."
                      v-model="formData.CustomerRefShipm"
                      hide-details
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Carrier Name"
                      hide-details
                      v-model="formData.CarrierName"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Carrier Street"
                      v-model="formData.CarrierStreet"
                      hide-details
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Carrier Postal Code + City"
                      hide-details
                      v-model="formData['CarrierPC-City']"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Carrier Country"
                      v-model="formData.CarrierCountry"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Est. Shipping Cost"
                      type="number"
                      filled
                      v-model="formData.EstShipCost"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Act. Shipping Cost"
                      type="number"
                      filled
                      v-model="formData.ActShipCost"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-menu
                      v-model="menu14"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormattedMomentjs14"
                          clearable
                          label="Est. Shipping Date"
                          readonly
                          filled
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.EstShipDate"
                        @input="menu14 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-menu
                      v-model="menu9"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormattedMomentjs9"
                          clearable
                          label="Act. Shipping Date"
                          readonly
                          filled
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.ActShipDate"
                        @input="menu9 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
      <br />
      <v-card>
        <v-card-subtitle><b> Invoices </b></v-card-subtitle>
        <form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Invoice No."
                  type="number"
                  filled
                  hide-details
                  v-model="formData['InvoiceNr-Wver']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu10"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs10"
                      clearable
                      label="Invoice Date"
                      readonly
                      filled
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData['InvoiceDate-Wver']"
                    @input="menu10 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="TL Invoiced"
                  type="number"
                  filled
                  v-model="formData['InvAmount-Wver']"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu11"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs11"
                      clearable
                      label="Due Date"
                      readonly
                      filled
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData['InvDueDate-Wver']"
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Payment Received"
                  type="number"
                  filled
                  hide-details
                  v-model="formData['PaymentRcvd-Wver']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu12"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs12"
                      clearable
                      label="Received Date"
                      readonly
                      filled
                      v-bind="attrs"
                      v-on="on"
                      hide-details=""
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData['PaymntRcvdDate-Wver']"
                    @input="menu12 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Got it
        </v-btn>
      </template>
    </v-snackbar>
    <v-bottom-sheet inset v-model="sheet">
      <v-list>
        <v-subheader>Export to Excel</v-subheader>
        <v-list-item
          v-for="tile in tiles"
          :key="tile.title"
          @click="
            sheet = false;
            exportToExcel(tile);
          "
        >
          <v-list-item-title>{{ tile }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import moment from "moment";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

export default {
  name: "ProjectView",
  data() {
    return {
      sheet: false,
      tiles: [],

      dataChanged: false,
      loading: false,

      snackbar: {
        show: false,
        text: "",
      },

      select: undefined,
      items: [],

      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      menu10: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,

      formData: {
        // Customer Details
        CustNumber: "",

        // Project Details
        ProjectCompleted: false,
        PONumber: "",
        OrderDate: "",
        Remarks: "",

        // Quotation
        QuotNumber: "",
        QuotDate: "",
        QuotRows: [
          {
            ArtNumber: "",
            Description: "",
            SerialNumber: "",
            Quantity: "",
            Price: "",
            Tax: "",
          },
        ],

        // Order
        OrdrAccDate: "",
        OrdrRows: [
          {
            id: 1,
            ArtNumber: "",
            Description: "",
            SerialNumber: "",
            Quantity: "",
            Price: "",
            Tax: "",
          },
        ],
        PaymentCondix: "",
        ShipmentCondix: "",

        // Dates
        ReqDelDate: "",
        EstDelDate: "",

        // CvO
        CvOInvNr: "",
        CvOInvDate: "",
        CvORows: [
          {
            id: 1,
            ArtNumber: "",
            Description: "",
            SerialNumber: "",
            Quantity: "",
            Price: "",
            Tax: "",
          },
        ],

        // Prepayment
        PrepayInvNr: "",
        PrepayInvDate: "",

        // Delivery
        DelRows: [
          {
            id: 1,
            ArtNumber: "",
            Description: "",
            SerialNumber: "",
            Quantity: "",
            Price: "",
            Tax: "",
          },
        ],

        // Shipping
        ShipRows: [
          {
            id: 1,
            Length: 0,
            Width: 0,
            Height: 0,
            "N/W": 0,
            "G/W": 0,
          },
        ],
        ShipInvNr: "",
        ShipInvDate: "",
        PackLstNr: "",
        PackLstDate: "",

        TotShipmVolume: "",

        TotNrOfBoxes: "",
        TotNettWeight: "",
        TotGrossWeight: "",

        CarrierAWB: "",
        CustomerRefShipm: "",

        CarrierName: "",
        CarrierStreet: "",
        "CarrierPC-City": "",
        CarrierCountry: "",

        EstShipCost: "",
        ActShipCost: "",
        EstShipDate: "",
        ActShipDate: "",

        // Invoice
        "InvoiceNr-Wver": "",
        "InvoiceDate-Wver": "",
        "InvAmount-Wver": "",
        "InvDueDate-Wver": "",
        "PaymentRcvd-Wver": "",
        "PaymntRcvdDate-Wver": "",
      },
    };
  },
  computed: {
    totalVolume() {
      var TotShipmVolume = 0;

      this.formData.ShipRows.forEach((row) => {
        TotShipmVolume +=
          Number(row.Height) * Number(row.Width) * Number(row.Length);
      });

      return TotShipmVolume;
    },
    totalNetWeight() {
      var TotNettWeight = 0;

      this.formData.ShipRows.forEach((row) => {
        TotNettWeight += Number(row["N/W"]);
      });

      return TotNettWeight;
    },
    totalGrossWeight() {
      var TotGrossWeight = 0;

      this.formData.ShipRows.forEach((row) => {
        TotGrossWeight += Number(row["G/W"]);
      });

      return TotGrossWeight;
    },

    computedDateFormattedMomentjs1() {
      return this.formData.OrderDate
        ? moment(this.formData.OrderDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs2() {
      return this.formData.QuotDate
        ? moment(this.formData.QuotDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs3() {
      return this.formData.OrdrAccDate
        ? moment(this.formData.OrdrAccDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs4() {
      return this.formData.ReqDelDate
        ? moment(this.formData.ReqDelDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs5() {
      return this.formData.EstDelDate
        ? moment(this.formData.EstDelDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs6() {
      return this.formData.CvOInvDate
        ? moment(this.formData.CvOInvDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs7() {
      return this.formData.ShipInvDate
        ? moment(this.formData.ShipInvDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs8() {
      return this.formData.PackLstDate
        ? moment(this.formData.PackLstDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs9() {
      return this.formData.ActShipDate
        ? moment(this.formData.ActShipDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs10() {
      return this.formData["InvoiceDate-Wver"]
        ? moment(this.formData["InvoiceDate-Wver"]).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs11() {
      return this.formData["InvDueDate-Wver"]
        ? moment(this.formData["InvDueDate-Wver"]).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs12() {
      return this.formData["PaymntRcvdDate-Wver"]
        ? moment(this.formData["PaymntRcvdDate-Wver"]).format(
            "dddd, DD MMMM YYYY"
          )
        : "";
    },
    computedDateFormattedMomentjs13() {
      return this.formData.PrepayInvDate
        ? moment(this.formData.PrepayInvDate).format("dddd, DD MMMM YYYY")
        : "";
    },
    computedDateFormattedMomentjs14() {
      return this.formData.EstShipDate
        ? moment(this.formData.EstShipDate).format("dddd, DD MMMM YYYY")
        : "";
    },
  },
  mounted() {
    var self = this;

    // Fetch exports list
    var data = JSON.stringify({
      token:
      localStorage.getItem("token"),
    });

    var config = {
      method: "post",
      url: "https://aceapi.diconde.biz/export/list",
      headers: {
        Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        self.tiles = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    // Companies
    data = JSON.stringify({
      token: localStorage.getItem("token"),
      table: "Companies",
    });

    config = {
      method: "post",
      url: "https://aceapi.diconde.biz/database/query",
      headers: {
        Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        self.items = response.data;

        if (self.$route.params && self.$route.params.id == "new") return;
        // Data
        data = JSON.stringify({
          token: localStorage.getItem("token"),
          table: "Projects",
          search: self.$route.params.id,
        });

        config = {
          method: "post",
          url: "https://aceapi.diconde.biz/database/query",
          headers: {
            Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log("JSON: " + JSON.stringify(response.data));
            if (response.data.length == 0 || response.data == null) {
              self.$router.push("/");
              return;
            }

            self.formData = response.data[0];

            self.formData.ProjectCompleted =
              self.formData.ProjectCompleted == "1" ? true : false;

            self.formData.CvORows = JSON.parse(self.formData.CvORows);
            self.formData.ShipRows = JSON.parse(self.formData.ShipRows);
            self.formData.DelRows = JSON.parse(self.formData.DelRows);
            self.formData.QuotRows = JSON.parse(self.formData.QuotRows);
            self.formData.OrdrRows = JSON.parse(self.formData.OrdrRows);

            self.select =
              self.items[
                self.items.findIndex(
                  (x) => x.CustNumber == response.data[0].CustNumber
                )
              ];

            setTimeout(function () {
              self.dataChanged = false;
            }, 10);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    addRow(array) {
      array.push({
        id: Object.keys(array).length + 1,
        ArtNumber: "",
        Description: "",
        Quantity: "",
        Price: "",
        Tax: "",
      });
    },
    addShipRow(array) {
      array.push({
        id: Object.keys(array).length + 1,
        Length: 0,
        Width: 0,
        Height: 0,
        "N/W": 0,
        "G/W": 0,
      });
    },
    removeRow(array, index) {
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    cloneRows(from, to) {
      if (confirm(`Do you want to copy ${from} to ${to}?`) == true) {
        this.formData[to] = JSON.parse(JSON.stringify(this.formData[from]));
      }
    },
    save() {
      var self = this;
      if (!this.formData.PONumber) {
        self.snackbar.show = true;
        self.snackbar.text = "No Project Number assigned.";
        return;
      }

      this.loading = true;

      this.formData["TotShipmVolume"] = Number(this.totalVolume).toFixed(3);
      this.formData["TotNettWeight"] = this.totalNetWeight;
      this.formData["TotGrossWeight"] = this.totalGrossWeight;
      this.formData["CustNumber"] = Number(this.select.CustNumber);
      this.$route.params.id = this.formData.PONumber;

      var data = JSON.stringify({
        token: localStorage.getItem("token"),
        table: "Projects",
        payload: this.formData,
      });

      var config = {
        method: "post",
        url: "https://aceapi.diconde.biz/database/update",
        headers: {
          Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          self.loading = false;
          self.dataChanged = false;

          window.removeEventListener("beforeunload", self.beforeunload);

          self.snackbar.show = true;
          self.snackbar.text = response.data.reason;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    exportToExcel(text) {
      function isJsonString(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      }

      var self = this;

      axios(
        "https://aceapi.diconde.biz/export/excel/" +
          this.formData.PONumber +
          "/" +
          text,
        {
          method: "get",

          headers: {
            Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
          },
          responseType: "blob",
          withCredentials: true,
        }
      )
        .then(function (response) {
          console.log(response);
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${self.formData.CustNumber || self.select.CustNumber}-${text}`;
          link.click();
          URL.revokeObjectURL(link.href);

          if (isJsonString(response.data)) {
            self.snackbar.show = true;
            self.snackbar.text = JSON.parse(response.data).message;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  watch: {
    "formData.PONumber"(val) {
      console.log(val);
      // Silently change URL parameter to val
      this.$router.replace({
        name: "ProjectView",
        params: {
          id: val,
        },
      });
    },
    formData: {
      handler() {
        if (this.select && Number(this.select.CustNumber)) {
          this.dataChanged = true;

          this.beforeunload = function (e) {
            var confirmationMessage =
              "It looks like you have been editing something. " +
              "If you leave before saving, your changes will be lost.";

            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
          };

          window.addEventListener("beforeunload", this.beforeunload);
        }
      },
      deep: true,
    },
    select: {
      handler() {
        this.formData.CustNumber = this.select ? this.select.CustNumber : "";
        this.dataChanged = true;
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      const answer = window.confirm(
        "You have made changes to the project. Are you sure you want to leave?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style>
table {
  table-layout: fixed;
  overflow-x: scroll;
  overflow-y: scroll;
}
</style>
