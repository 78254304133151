<template>
  <div>
    <v-app-bar elevate-on-scroll app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title
        ><b>{{ name }}</b></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon @click="$router.push(button.to)" v-for="button in buttons" v-bind:key="button.name">
        <v-icon>{{ button.icon }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      width="300"
      temporary
      app
      clipped
      style="height: 100vh; padding-top: 56px"
    >
      <v-list nav dense>
        <v-list-item-group
        >
          <v-list-item @click="$router.push('/')">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Requests</v-list-item-title>
          </v-list-item>

          <v-list-item disabled @click="$router.push('/service/requests')">
            <v-list-item-icon>
              <v-icon>mdi-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Service Requests</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/ai/secretary')">
            <v-list-item-icon>
              <v-icon>mdi-email-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>AI Secretary</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "HeaderComp",
  props: {
    name: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    isActiveIcon: function(route) {
      const bool = (route == this.currentRoute);
      return bool;
    }
  },
};
</script>
