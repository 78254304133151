<template>
  <v-container>
    <v-app-bar elevate-on-scroll app dark>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title><b>Package Lists</b></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="exportToExcel()">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-card-actions>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Package List No.</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Invoice No.</th>
                <th class="text-left">Project No.</th>
                <th class="text-left">Package List Date</th>
                <th class="text-left">TL Invoiced</th>
                <th class="text-left">Payment Received</th>
                <th class="text-left">Due Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in packLists" :key="item.PONumber">
                <td>{{ item.PackageListNo }}</td>
                <td>{{ item.CustNumber }}</td>
                <td>{{ item["InvoiceNr-Wver"] }}</td>
                <td>{{ item.PONumber }}</td>
                <td>{{ item.PackLstDate }}</td>
                <td>{{ item["InvAmount-Wver"] }}</td>
                <td>{{ item["PaymentRcvd-Wver"] }}</td>
                <td>{{ item['InvDueDate-Wver'] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
var axios = require("axios").default;

export default {
  name: "PackLists",
  data: () => ({
    packLists: [],
    companies: []
  }),
  methods: {
    exportToExcel() {
      axios(
        "https://aceapi.diconde.biz/export/packlist",
        {
          method: "get",

          headers: {
            Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
          },
          responseType: "blob",
          withCredentials: true,
        }
      )
        .then(function (response) {
          console.log(response);
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${new Date().toLocaleDateString()}-PackList.xlsx`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    var self = this;

    // Companies
    var data = JSON.stringify({
      token: localStorage.getItem("token"),
      table: "Companies",
    });

    var config = {
      method: "post",
      url: "https://aceapi.diconde.biz/database/query",
      headers: {
        Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        self.companies = response.data;

        data = JSON.stringify({
          token: localStorage.getItem("token"),
          table: "PackLists",
        });

        config = {
          method: "post",
          url: "https://aceapi.diconde.biz/database/query",
          headers: {
            Authorization: "Basic QWNlLS1QYW5lbDpBY2VATmw5MzAxWnY2LTM=",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            self.packLists = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>
